<script setup lang="ts">
import dayjs from "dayjs";
import type { Events } from "~/types/directus-collections.d.ts";

const props = defineProps<{
  event: Events;
}>();

const startTime = dayjs(props.event.start).format("h:mma");
const endTime = dayjs(props.event.end).format("h:mma");
const timeString = `${startTime} - ${endTime}`;

const eventLocation = ref(null);

if (props.event.location !== null || props.event.address !== null) {
  eventLocation.value =
    props.event.address != null
      ? props.event.address
      : await useReverseGeocode(props.event.location.coordinates[0], props.event.location.coordinates[1]);
}

// PROBLEM - the user object will not be available to the SERVER SIDE RENDERING, meaning that it will try and read null and fail

const user = useUser();
</script>

<template>
  <div class="relative col-span-1 mt-16 self-stretch rounded-md bg-white px-12 pb-12 pt-10 text-center">
    <div class="absolute -top-16 left-1/2 -translate-x-1/2 rounded-lg bg-secondary p-5 text-center font-script text-accentDark">
      <div class="text-3xl font-bold leading-[0.8]">
        {{ dayjs(event.start).format("D") }}
      </div>
      <div class="font-bold">{{ dayjs(event.start).format("MMMM") }}</div>
    </div>
    <div class="mx-auto flex flex-col items-center gap-3 pb-4 text-center text-secondary">
      <div>{{ timeString }}</div>
      <div v-if="event.location != null || event.address != null" class="mx-1 h-[2px] w-3 bg-secondary"></div>
      <div v-if="eventLocation">{{ eventLocation }}</div>

      <div
        v-if="user.user != null && event.event_participants.find((p) => p.user === user?.user?.id)"
        class="rounded-lg bg-secondary p-2 text-sm font-semibold text-white"
      >
        <font-awesome-icon icon="fa-solid fa-star" />
        REGISTERED
      </div>
    </div>
    <NuxtLink :to="{ name: 'events-slug', params: { slug: event.slug } }" class="pb-4 text-2xl font-semibold text-accentDark">
      {{ event.title }}
    </NuxtLink>
    <div class="text-lg text-accentDark">
      {{ event.summary }}
    </div>
    <slot name="button"></slot>
  </div>
</template>
